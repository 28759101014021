@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: Signature;
  src: url("./fonts/JustSignature.ttf");
}

@font-face {
  font-family: BaarMetanoia;
  src: url("./fonts/BaarMetanoia.ttf");
}

@font-face {
  font-family: BaarMetanoia;
  src: url("./fonts/BaarMetanoiaBold.ttf");
  font-weight: bold;
}

@font-face {
  font-family: BaarMetanoia;
  src: url("./fonts/BaarMetanoiaBoldItalic.ttf");
  font-style: italic;
  font-weight: bold;
}

@font-face {
  font-family: BaarMetanoia;
  src: url("./fonts/BaarMetanoiaItalic.ttf");
  font-style: italic;
}

@font-face {
  font-family: PTSans;
  src: url("./fonts/PTSans.ttf");
}

@font-face {
  font-family: PTSans;
  src: url("./fonts/PTSansBold.ttf");
  font-weight: bold;
}

@font-face {
  font-family: PTSans;
  src: url("./fonts/PTSansBoldItalic.ttf");
  font-style: italic;
  font-weight: bold;
}

@font-face {
  font-family: PTSans;
  src: url("./fonts/PTSansItalic.ttf");
  font-style: italic;
}

html {
  scroll-behavior: smooth;
}
